import { adapter, elqAdapter, ipAddressAdapter } from "@/adapter";
import md5 from "js-md5";
import getters from "@/store/getters";
import router from "@/router/index";
import { gtmManager } from "@/gtm";
import { setCookie, getCookie } from "@/cookies";

let changeQuantityProcessing = false;

const paymentsMap = {
  "a56cec33-44c5-4b43-bcf1-dfd7abad91ef": {
    code: "KK",
    name: "Szybki przelew"
  },
  "75905b0b-60d8-4517-84ae-3fa020372f71": { code: "PO", name: "Za pobraniem" },
  "9888eae6-8aba-4933-83d3-657502fd611c": {
    code: "PP",
    name: "Przelew tradycyjny"
  },
  "7036b6fe-22e3-4b0c-9a77-d4260a4c59da": {
    code: "AO",
    name: "Płatność autoodnawialna"
  }
};

const acceptedPayments = [
  "a56cec33-44c5-4b43-bcf1-dfd7abad91ef",
  "75905b0b-60d8-4517-84ae-3fa020372f71",
  "9888eae6-8aba-4933-83d3-657502fd611c",
  "7036b6fe-22e3-4b0c-9a77-d4260a4c59da"
];

export const calculateNewQuantity = function(quantity, direction) {
  if (direction === "increase") {
    return quantity + 1;
  } else if (direction === "decrease") {
    if (quantity > 1) {
      return quantity - 1;
    } else {
      return 1;
    }
  } else {
    throw new Error("Podaj poprawny parametr - direction");
  }
};

function transformData(data) {
  const order = data[0];
  let transformedData = {
    orderGuid: order.orderGuid,
    orderGuidDisplay: order.orderGuidDisplay,
    delivery: {
      id: order.deliveryId,
      name: order.deliveryName,
      isAvailable: true,
      isSelected: true
    },
    payment: {
      code: order.paymentCode,
      name: order.paymentName,
      isAvailable: true,
      isSelected: true
    },
    state: order.realizationState,
    amount: {
      net: order.net,
      gross: order.gross,
      vat: order.tax
    },
    recurring: {
      state: order.recurringState,
      resignationDate: order.recurringResignationDate,
      nextOrderId: order.nextOrderGuid
    }
  };
  let items = [];
  for (let itemId in order.items) {
    const inputItem = order.items[itemId];
    items.push({
      product: {
        name: inputItem.productName,
        description: inputItem.productDescription,
        typeName: inputItem.productType,
        url: inputItem.productUrl,
        imageUrl: inputItem.productImageUrl
      },
      quantity: inputItem.quantity,
      unitPrice: {
        net: inputItem.net,
        gross: inputItem.gross,
        vat: inputItem.vat,
        strikedNet: inputItem.strikedNet,
        strikedGross: inputItem.strikedGross,
        strikedVat: inputItem.strikedVat,
        rabateNet: inputItem.rabateNet,
        rabateGross: inputItem.rabateGross
      },
      price: {
        net: inputItem.net,
        gross: inputItem.gross,
        vat: inputItem.vat,
        strikedNet: inputItem.strikedNet,
        strikedGross: inputItem.strikedGross,
        strikedVat: inputItem.strikedVat,
        rabateNet: inputItem.rabateNet,
        rabateGross: inputItem.rabateGross
      },
      isAvailable: inputItem.isAvailable,
      overlaps: {}
    });
  }
  transformedData.items = items;
  return [transformedData];
}

export default {
  loadBasketData(context) {
    return adapter.getPage(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        cartType: getters.getCartType(context.state)
      },
      {
        success: basketPage => {
          basketPage.isAvailable = basketPage.isRealizationAvailable;
          let items = [];
          for (let offer_idx in basketPage.cartOffers) {
            const offer = basketPage.cartOffers[offer_idx];
            items.push({
              id: offer_idx,
              cartOfferId: offer.id,
              product: {
                code: offer.offerCode,
                name: offer.name,
                description: offer.description,
                imageUrl: "",
                url: ""
              },
              quantity: offer.quantity,
              unitPrice: offer.unitPrice,
              price: offer.price
            });
          }

          let payments = [];
          for (let payment_idx in basketPage.availablePaymentForms) {
            const payment = basketPage.availablePaymentForms[payment_idx];

            let acceptedPayment = acceptedPayments.includes(payment.id);

            if (!acceptedPayment) {
              continue;
            }
            payments.push({
              id: payment.id,
              code: paymentsMap[payment.id]["code"],
              name: paymentsMap[payment.id]["name"],
              isAvailable: true,
              isSelected: payment.isSelected
            });
          }

          context.commit("BASKET_PAGE_REPLACE_ITEMS", items);
          context.commit("BASKET_PAGE_SET_AMOUNT", basketPage.price);
          context.commit("BASKET_PAGE_ADD_DELIVERIES", []);
          context.commit("BASKET_PAGE_ADD_PAYMENTS", payments);

          // if (items.length === 0) {
          //   context.dispatch("removeRabateCode");
          // } else if (basketPage.rabateCodes.length > 0) {
          //   context.commit("BASKET_PAGE_SET_RABATE", basketPage.rabateCodes[0]);
          // }
        },
        failure: () => {
          context.commit("SHOW_ALERT", { message: "Błąd dodania do koszyka." });
        }
      }
    );
  },
  clearBasketItems(context) {
    adapter.clearBasket(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        cartType: getters.getCartType(context.state)
      },
      {
        success: () => {
          gtmManager.clear(context);
          context.commit("BASKET_PAGE_CLEAR_ITEMS");
        },
        failure: () => {
          context.commit("SHOW_ALERT", { message: "Błąd czyszczenia koszyka" });
        }
      }
    );
  },
  createNewSessionKey(context) {
    const sessionKey = "user_session_id";
    const sessionId = md5(Date.now().toString());
    const service = getters.getService(context.state).domain;
    setCookie(sessionKey, sessionId, service);
    context.commit("SET_SESSION_ID", sessionId);
  },
  getOrCreateSessionKey(context) {
    const sessionKey = "user_session_id";
    let sessionId = getCookie(sessionKey);
    if (sessionId === null) {
      sessionId = md5(Date.now().toString());
    }
    const service = getters.getService(context.state).domain;
    setCookie(sessionKey, sessionId, service);
    context.commit("SET_SESSION_ID", sessionId);
  },
  setUserIsAuthenticated: async (context, isUserAuthenticated) => {
    context.commit("SET_USER_IS_AUTHENTICATED", isUserAuthenticated);
    const service = getters.getService(context.state).domain;
    setCookie("is_user_authenticated", isUserAuthenticated, service);
  },
  checkUserIsAuthenticated: context => {
    const cookieValue = getCookie("is_user_authenticated");
    const isUserAuthenticated =
      cookieValue === "true" || cookieValue === "True";
    context.dispatch("setUserIsAuthenticated", isUserAuthenticated);
    if (isUserAuthenticated) {
      context.dispatch("loadCustomerData");
    } else {
      context.commit("SET_CUSTOMER_DATA", undefined);
    }
  },
  addProductToBasket: (context, productData) => {
    return adapter.insertToBasket(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        productData: productData
      },
      {
        success: () => {},
        failure: () => {
          context.commit("SHOW_ALERT", {
            message: "Błąd dodania do koszyka"
          });
        }
      }
    );
  },
  addProductToBasketWithProductCode: async (context, productData) => {
    await context.dispatch("addProductToBasket", productData);
    return context.dispatch("loadBasketData");
  },
  addProductToBasketWithProductCodeOrIncreaseQuantity: async (
    context,
    productData
  ) => {
    const itemInBasket = context.getters.getBasketItems.find(
      item => item.product.code === productData.productCode
    );
    if (itemInBasket) {
      try {
        await context.dispatch("changeItemQuantity", [
          itemInBasket,
          "increase"
        ]);
        return context.dispatch("loadBasketData");
      } catch (error) {
        console.error(error);
      }
    } else {
      return context.dispatch("addProductToBasketWithProductCode", productData);
    }
  },
  changeItemQuantity: (context, [item, direction]) => {
    if (changeQuantityProcessing) {
      return;
    }
    changeQuantityProcessing = true;

    let basketItemId = item.id;
    let cartType = "Standard";
    basketItemId = item.cartOfferId;
    const newQuantity = calculateNewQuantity(item.quantity, direction);

    return adapter.changeQuantity(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        cartType: cartType,
        newQuantity: newQuantity,
        basketItemId: basketItemId
      },
      {
        success: basketPage => {
          changeQuantityProcessing = false;
          let basketItem;

          context.commit("BASKET_PAGE_SET_AMOUNT", basketPage.price);
          basketItem = basketPage.cartOffers.find(i => i.id === basketItemId);
          context.commit("BASE_PAGE_UPDATE_ITEM_QUANTITY_WEAVER", {
            itemId: basketItemId,
            quantity: basketItem.quantity,
            price: basketItem.price
          });

          if (basketPage.rabateCodes.length > 0) {
            context.commit("BASKET_PAGE_SET_RABATE", basketPage.rabateCodes[0]);
          }

          if (direction === "increase") {
            gtmManager.increaseQuantity(context, basketItemId);
          } else if (direction === "decrease") {
            gtmManager.decreaseQuantity(context, basketItemId);
          }
        },
        failure: () => {
          changeQuantityProcessing = false;
          context.commit("SHOW_ALERT", { message: "Błąd zmiany ilości" });
        }
      }
    );
  },
  deleteItemFromBasket: (context, item) => {
    let basketItemId = item.id;
    basketItemId = item.cartOfferId;
    return adapter.deleteItem(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        cartType: getters.getCartType(context.state),
        basketItemId: basketItemId
      },
      {
        success: basketPage => {
          context.commit("BASKET_PAGE_DELETE_ITEM", basketItemId);
          if (basketPage.rabateCodes.length > 0) {
            context.commit("BASKET_PAGE_SET_RABATE", basketPage.rabateCodes[0]);
          }
          context.commit("BASKET_PAGE_SET_AMOUNT", basketPage.amount);
        },
        failure: error => {
          context.commit("SHOW_ALERT", { message: "Błąd usuwania z koszyka" });
          throw new Error(error.message);
        }
      }
    );
  },
  deleteNestedItemFromBasket: (context, { nestedItemId }) => {
    return adapter.deleteItem(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        cartType: getters.getCartType(context.state),
        basketItemId: nestedItemId
      },
      {
        success: basketPage => {
          context.commit("BASKET_PAGE_SET_AMOUNT", basketPage.amount);
        },
        failure: error => {
          context.commit("SHOW_ALERT", { message: "Błąd usuwania z koszyka" });
          throw new Error(error.message);
        }
      }
    );
  },
  setBackToShoppingUrl: (context, url) => {
    const service = getters.getService(context.state).retUrl;
    if (url.search(service) >= 0) {
      context.commit("SET_BACK_TO_SHOPPING_URL", url);
    }
  },
  loadCustomerData: context => {
    return adapter.getCustomer(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        cartType: getters.getCartType(context.state)
      },
      {
        success: customerData => {
          context.commit("SET_CUSTOMER_HASH", md5(customerData.email));
          context.commit("SET_CUSTOMER_DATA", customerData);
          context.commit("BASKET_PAGE_SET_LOGGED_CUSTOMER", customerData);
        },
        failure: () => {
          context.commit("SHOW_ALERT", {
            message: "Użytkownik niezalogowany!"
          });
        }
      }
    );
  },
  loginCustomer: (context, data) => {
    const userData = {
      login: data.login,
      password: data.password,
      sessionDuration: 30,
      sessionId: getters.getSessionId(context.state)
    };
    return adapter.login(context, userData, {
      success: loginData => {
        if (loginData && loginData.sessionId) {
          const service = getters.getService(context.state).domain;
          setCookie("user_session_id", loginData.sessionId, service);
          context.commit("SET_SESSION_ID", loginData.sessionId);
        }
        context.commit("SET_CUSTOMER_HASH", md5(data.login));
        context.dispatch("setUserIsAuthenticated", true);
        context.dispatch("loadCustomerData");
        context.commit("SET_AUTHENTICATION_REFERER", "login");
        gtmManager.formSubmit(context, "logowanie");
      },
      failure: error => {
        if (
          error.fields &&
          error.fields.length > 0 &&
          error.fields[0].message
        ) {
          throw new Error(error.fields[0].message);
        } else {
          throw new Error(error.message);
        }
      }
    });
  },
  registerCustomer: async (context, data) => {
    const userData = {
      login: data.login,
      password: data.password,
      regulationConsent: data.regulationConsent,
      emailMarketingConsent: data.emailMarketingConsent,
      sessionId: getters.getSessionId(context.state)
    };
    return adapter.deficientRegister(context, userData, {
      success: registerData => {
        if (registerData && registerData.sessionId) {
          const service = getters.getService(context.state).domain;
          setCookie("user_session_id", registerData.sessionId, service);
          context.commit("SET_SESSION_ID", registerData.sessionId);
        }
        context.commit("SET_CUSTOMER_HASH", md5(data.login));
        context.dispatch("setUserIsAuthenticated", true);
        context.dispatch("loadCustomerData");
        context.commit("SET_AUTHENTICATION_REFERER", "register");
        gtmManager.formSubmit(context, "rejestracja");
        if (data.emailMarketingConsent) {
          elqAdapter.registerCustomer({
            email: data.login,
            elqCustomerGUID: data.elqCustomerGUID,
            emailMarketingConsent: data.emailMarketingConsent,
            items: getters.getBasketItems(context.state)
          });
        }
      },
      failure: error => {
        if (
          error.fields &&
          error.fields.length > 0 &&
          error.fields[0].message
        ) {
          throw new Error(error.fields[0].message);
        } else {
          throw new Error(error.message);
        }
      }
    });
  },
  showAlert(context, message) {
    context.commit("SHOW_ALERT", message);
  },
  selectDelivery: (context, deliveryId) => {
    adapter.selectDelivery(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        deliveryId: deliveryId,
        cartType: getters.getCartType(context.state)
      },
      {
        success: deliveryData => {
          // translacja form dostawy na aktualnie używane w koszyku
          const paymentCode = getters.getChosenPayment(context.state);
          let deliveriesMap = {
            "248e11be-16d0-4e8d-8f46-ccb0c2bb660a": {
              id: 1,
              name: "Poczta Polska"
            },
            "9fd83dcb-2874-4d94-9065-92ab74fb872d": {
              id: 11,
              name: "Elektroniczna"
            }
          };
          if (paymentCode === "75905b0b-60d8-4517-84ae-3fa020372f71") {
            deliveriesMap = {
              "248e11be-16d0-4e8d-8f46-ccb0c2bb660a": {
                id: 1,
                name: "Poczta Polska"
              },
              "9fd83dcb-2874-4d94-9065-92ab74fb872d": {
                id: 11,
                name: "Elektroniczna"
              }
            };
          }

          let deliveriesKeys = [];
          for (let key in deliveriesMap) {
            deliveriesKeys.push(key);
          }

          let deliveries = [];
          for (let delivery_idx in deliveryData.availableFormsOfDistribution) {
            const delivery =
              deliveryData.availableFormsOfDistribution[delivery_idx];
            if (deliveriesKeys.includes(delivery.id)) {
              deliveries.push({
                id: deliveriesMap[delivery.id]["id"],
                weaverId: delivery.id,
                name: deliveriesMap[delivery.id]["name"],
                isAvailable: true,
                isSelected: delivery.isSelected,
                price: {
                  gross: 0,
                  currencyIsoCode: "PLN"
                }
              });
            }
          }
          deliveryData.deliveries = deliveries;
          context.commit("BASKET_PAGE_ADD_DELIVERIES", deliveryData.deliveries);

          context.commit("BASKET_PAGE_SET_AMOUNT", deliveryData.price);
          context.commit("SET_CHOSEN_DELIVERY_ID", deliveryId);
          context.commit("SET_DELIVERY_ADDRESS_DATA", deliveryData);
        },
        failure: () => {
          context.commit("SHOW_ALERT", {
            message: "Błąd podczas wyboru sposobu dostawy."
          });
        }
      }
    );
  },
  selectPayment: (context, paymentCode) => {
    adapter.selectPayment(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        paymentCode: paymentCode,
        cartType: getters.getCartType(context.state)
      },
      {
        success: paymentData => {
          // translacja form płatności na aktualnie używane w koszyku
          let payments = [];
          for (let payment_idx in paymentData.availablePaymentForms) {
            const payment = paymentData.availablePaymentForms[payment_idx];

            let acceptedPayment = acceptedPayments.includes(payment.id);

            if (!acceptedPayment) {
              continue;
            }
            payments.push({
              id: payment.id,
              code: paymentsMap[payment.id]["code"],
              name: paymentsMap[payment.id]["name"],
              isAvailable: true,
              isSelected: payment.isSelected
            });
          }
          paymentData.payments = payments;

          // translacja form dostawy na aktualnie używane w koszyku
          let deliveriesMap = {
            "248e11be-16d0-4e8d-8f46-ccb0c2bb660a": {
              id: 1,
              name: "Poczta Polska"
            },
            "9fd83dcb-2874-4d94-9065-92ab74fb872d": {
              id: 11,
              name: "Elektroniczna"
            }
          };
          if (paymentCode === "75905b0b-60d8-4517-84ae-3fa020372f71") {
            deliveriesMap = {
              "248e11be-16d0-4e8d-8f46-ccb0c2bb660a": {
                id: 1,
                name: "Poczta Polska"
              },
              "9fd83dcb-2874-4d94-9065-92ab74fb872d": {
                id: 11,
                name: "Elektroniczna"
              }
            };
          }

          let deliveriesKeys = [];
          for (let key in deliveriesMap) {
            deliveriesKeys.push(key);
          }

          let deliveries = [];
          for (let delivery_idx in paymentData.availableFormsOfDistribution) {
            const delivery =
              paymentData.availableFormsOfDistribution[delivery_idx];
            context.commit("SET_DEBUG", {
              dmap: deliveriesMap,
              dkeys: deliveriesKeys,
              deliv: delivery
            });
            if (deliveriesKeys.includes(delivery.id)) {
              deliveries.push({
                id: deliveriesMap[delivery.id]["id"],
                weaverId: delivery.id,
                name: deliveriesMap[delivery.id]["name"],
                isAvailable: true,
                isSelected: delivery.isSelected,
                price: {
                  gross: 0,
                  currencyIsoCode: "PLN"
                }
              });
            }
          }
          paymentData.deliveries = deliveries;

          context.commit("BASKET_PAGE_ADD_DELIVERIES", paymentData.deliveries);
          context.commit("BASKET_PAGE_ADD_PAYMENTS", paymentData.payments);
          context.commit("BASKET_PAGE_SET_AMOUNT", paymentData.price);
          context.commit("SET_CHOSEN_PAYMENT_CODE", paymentCode);
        },
        failure: () => {
          context.commit("SHOW_ALERT", {
            message: "Błąd podczas wyboru sposobu płatności."
          });
        }
      }
    );
  },

  makeOrderForCustomer: (context, data) => {
    let basketData = {
      sessionId: getters.getSessionId(context.state),
      cartType: getters.getCartType(context.state),
      ip: getters.getUserIpAddress(context.state),
      redirectUrl: data.url
    };
    const onSuccess = orderData => {
      const orders = orderData.orders.map(item => {
        return { id: item.id, orderGuid: item.orderGuid };
      });

      const basketData = {
        orders: orders,
        transactionId: orderData.transactionId,
        isRedirectRequired: orderData.isRedirectRequired,
        redirectUrl: orderData.redirectUrl
      };

      context.commit("SET_ORDER_DATA", basketData);
      const service = getters.getService(context.state).domain;
      setCookie("transaction_id", orderData.transactionId, service);

      const redirectUrl = getters.getRedirectUrlForPayment(context.state);
      if (redirectUrl === null) {
        router.push({ name: "OrderConfirm" });
      } else {
        window.location.href = redirectUrl;
      }
    };
    const onFailure = () => {
      throw new Error("Błąd podczas składania zamówienia.");
    };

    if (data.customerStatus === "existing") {
      return adapter.realizeForExistingCustomer(context, basketData, {
        success: onSuccess,
        failure: onFailure
      });
    }
    if (data.customerStatus === "new") {
      basketData.regulationConsent = data.salesReg;
      basketData.emailMarketingConsent = data.newsletter;
      basketData.invoiceAddress = data.invoiceAddress;
      basketData.deliveryAddress = data.deliveryAddress;
      return adapter.realizeForNewCustomer(context, basketData, {
        success: onSuccess,
        failure: onFailure
      });
    }
  },
  editCustomer: async (context, data) => {
    return adapter.editCustomer(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        payerAddress: data.payerAddress,
        reciverAddress: data.reciverAddress
      },
      {
        success: invoiceData => {
          context.commit("ADD_INVOICE_DATA", invoiceData);
          context.commit("SET_DELIVERY_ADDRESS_DATA", invoiceData);
        },
        failure: error => {
          let mapFieldErrorsString = "";
          if (error.fields) {
            const mapFieldErrors = error.fields.map(field => field.message);
            mapFieldErrorsString = mapFieldErrors.join(" ");
          }
          const errorMessage = `Błąd podczas dodawania danych do faktury. ${mapFieldErrorsString}`;
          context.commit("SHOW_ALERT", {
            message: errorMessage
          });
          throw new Error(errorMessage);
        }
      }
    );
  },
  getOrdersByTransaction: context => {
    let transactionId = getters.getTransactionId(context.state);
    if (transactionId === null || transactionId === undefined) {
      transactionId = getCookie("transaction_id");
    }
    if (transactionId === null || transactionId === undefined) {
      return;
    }
    context.commit("SET_TRANSACTION_ID", transactionId);

    return adapter.getOrdersByTransaction(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        transactionId: transactionId
      },
      {
        success: data => {
          data = transformData(data);
          context.commit("SET_DATA_LAYER");
          context.commit("SET_ORDERS_FROM_TRANSACTION_HISTORY", data);

          gtmManager.ordersConfirm(context, data, transactionId);
          gtmManager.purchaseEvent(context, transactionId);
          if (data.length > 0) {
            context.commit("SET_CHOSEN_DELIVERY_ID", data[0].delivery.id);
            context.commit("SET_CHOSEN_PAYMENT_CODE", data[0].payment.code);
          } else {
            context.commit("SET_CHOSEN_DELIVERY_ID", "default");
            context.commit("SET_CHOSEN_PAYMENT_CODE", "default");
          }
        },
        failure: () => {
          const errorMessage = "Błąd podczas dodawania danych zamówienia.";
          context.commit("SHOW_ALERT", {
            message: errorMessage
          });
          throw new Error(errorMessage);
        }
      }
    );
  },
  logOut: context => {
    adapter.logOut(
      context,
      {
        sessionId: getters.getSessionId(context.state)
      },
      {
        success: () => {
          gtmManager.clear(context);
          context.commit("BASKET_PAGE_CLEAR_ITEMS");
          context.dispatch("clearUserData");
          context.dispatch("createNewSessionKey");
        },
        failure: () => {
          context.commit("SHOW_ALERT", {
            message: "Błąd podczas wylogowania użytkownika."
          });
        }
      }
    );
  },
  gtmCheckoutEvent: context => {
    gtmManager.checkout(context);
  },
  clearUserData: context => {
    context.dispatch("setUserIsAuthenticated", false);
    context.commit("SET_USER_NO_REGISTER", false);
    context.commit("SET_CUSTOMER_DATA", undefined);
    context.commit("SET_CUSTOMER_HASH", undefined);
  },
  useRabateCode: (context, rabateCode) => {
    return adapter.useRabateCode(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        cartType: getters.getCartType(context.state),
        rabateCode: rabateCode
      },
      {
        success: basketPage => {
          if (basketPage.rabateCodes.length > 0) {
            context.commit("BASKET_PAGE_SET_RABATE", basketPage.rabateCodes[0]);
            context.commit("BASKET_PAGE_SET_AMOUNT", basketPage.amount);
          }
        },
        failure: error => {
          throw error.message;
        }
      }
    );
  },
  removeRabateCode: context => {
    return adapter.removeRabateCode(
      context,
      {
        cartType: getters.getCartType(context.state),
        sessionId: getters.getSessionId(context.state)
      },
      {
        success: basketPage => {
          context.commit("BASKET_PAGE_SET_RABATE", undefined);
          context.commit("BASKET_PAGE_SET_AMOUNT", basketPage.amount);
        },
        failure: () => {}
      }
    );
  },
  updateStartSubscriptionFromOwned: (context, basketItemId) => {
    return adapter.startSubscriptionFromOwned(
      context,
      {
        sessionId: getters.getSessionId(context.state),
        cartType: getters.getCartType(context.state),
        basketItemId: basketItemId
      },
      {
        success: () => {},
        failure: error => {
          throw error;
        }
      }
    );
  },
  setIpAddress: async context => {
    const userIpAddress = await ipAddressAdapter.getIpAddress();
    context.commit("SET_USER_IP_ADDRESS", userIpAddress);
  }
};
