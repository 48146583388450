<template>
  <div class="order">
    <Header />
    <Steps />
    <div class="container">
      <div class="col2-3">
        <CustomerEmail />

        <div class="container__order">
          <div class="form__box">
            <p class="form__box__title">Wybierz sposób płatności</p>

            <div class="paymentOptions">
              <div
                class="paymentOptions__option"
                v-for="paymentOption in paymentOptions"
                :key="paymentOption.code"
              >
                <input
                  type="checkbox"
                  :class="
                    chosenPayment[0] === paymentOption.code
                      ? 'orderInput orderInput__checked'
                      : 'orderInput'
                  "
                  :id="paymentOption.code"
                  v-model="chosenPayment"
                  @change="
                    setPaymentOption(paymentOption);
                    isCashOnDelivery(paymentOption.code);
                  "
                />
                <label
                  :class="
                    $v.chosenPayment.$dirty && $v.chosenPayment.$invalid
                      ? 'orderInputLabel orderInputLabel--payment coloredRed'
                      : 'orderInputLabel orderInputLabel--payment'
                  "
                  :for="paymentOption.code"
                  @click="$v.chosenPayment.$touch()"
                >
                  <!--                  <img-->
                  <!--                    class="paymentInput__img"-->
                  <!--                    src="https://via.placeholder.com/25x25"-->
                  <!--                  />-->
                  <p class="paymentInput__title">{{ paymentOption.name }}</p>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="container__order">
          <div class="form__box form__box--min-height">
            <p class="form__box__title">Sposób dostawy</p>

            <p
              v-if="!isPaymentChosen"
              class="form__box__txt form__box__txt--margin"
            >
              Wybierz sposób platności, żeby zobaczyć formy dostawy.
            </p>

            <div class="deliveryOptions">
              <div
                class="inputBox deliveryOptions__option"
                v-for="deliveryOption in deliveryOptions"
                :key="deliveryOption.id"
              >
                <input
                  type="checkbox"
                  :class="
                    chosenDelivery[0] === deliveryOption.id ||
                    deliveryOption.id === 8
                      ? 'orderInput orderInput__checked'
                      : 'orderInput'
                  "
                  :id="deliveryOption.id"
                  v-model="chosenDelivery"
                  @change="setDeliveryOption(deliveryOption)"
                />
                <label
                  :class="
                    $v.chosenDelivery.$dirty && $v.chosenDelivery.$invalid
                      ? 'orderInputLabel coloredRed'
                      : 'orderInputLabel'
                  "
                  :for="deliveryOption.id"
                  @click="$v.chosenDelivery.$touch()"
                  >{{ deliveryOption.name }}</label
                >
              </div>
            </div>
          </div>
          <p class="form__box__deliveryCost" v-if="isDeliveryAndPaymentChosen">
            Koszt dostawy:
            <span class="form__box__deliveryCost__price">
              <FormattedPrice
                :price="getChosenDeliveryPrice"
                :currency="getChosenDeliveryPriceCurrency"
              />
            </span>
            <span
              v-if="isPln(getChosenDeliveryPriceCurrency)"
              class="delivery__tax__text"
            >
              (z VAT)</span
            >
          </p>
        </div>

        <div
          v-if="!!payerAndReciverAddressErrors.length"
          class="container__order"
        >
          <ErrorBox :errors="payerAndReciverAddressErrors" ref="errorBox" />
        </div>

        <DeliveryAddressForm
          ref="deliveryAddressForm"
          :isPhysicalDeliverySelected="isPhysicalDeliverySelected"
          v-on:show-company-order-info="showCompanyOrderInfo = $event"
        />

        <div
          v-if="!isUserSynchronized"
          class="container__order container__order--vatBox"
        >
          <div class="form__box">
            <label
              v-if="!isUserSynchronized && canGetInvoice()"
              class="form__box__checkbox"
              for="vat"
            >
              Chcę otrzymać fakturę na działalność
              <input
                v-model="VATInvoice"
                type="checkbox"
                id="vat"
                @change="setUserType()"
              />
              <span class="checkmark"></span>
            </label>

            <label
              v-if="!isUserSynchronized && !canGetInvoice()"
              class="form__box__checkbox"
              for="vat"
            >
              Aby otrzymać fakturę prosimy o kontakt mailowy z Biurem Obsługi
              Klientów -
              <a
                href="mailto:klienci@grupazpr.pl"
                class="rodo__link"
                target="_blank"
                >klienci@grupazpr.pl</a
              >
              <input v-model="VATInvoice" type="checkbox" disabled />
              <span class="checkmark checkmark__disabled"></span>
            </label>

            <CompanyOrderInfo v-if="VATInvoice && showCompanyOrderInfo" />

            <div
              v-if="
                VATInvoice &&
                  !showCompanyOrderInfo &&
                  isPln(getChosenDeliveryPriceCurrency)
              "
              class="invoice"
              id="vat_invoice"
            >
              <p class="invoice__title">Podaj dane do faktury VAT</p>

              <div>
                <div
                  class="form__box"
                  :class="{
                    'form__box--error':
                      $v.VATInvoiceForm.nip.$dirty &&
                      $v.VATInvoiceForm.nip.$invalid
                  }"
                >
                  <p class="input__title">NIP</p>
                  <div
                    class="form__inputBox"
                    :class="{
                      'form__inputBox--valid': !$v.VATInvoiceForm.nip.$invalid
                    }"
                  >
                    <input
                      v-model="VATInvoiceForm.nip"
                      @blur="$v.VATInvoiceForm.nip.$touch"
                      :readonly="isExistingNIPSet"
                      type="tel"
                      class="form__input"
                      placeholder="podaj NIP"
                      minlength="10"
                      maxlength="13"
                    />
                  </div>
                  <p
                    v-if="
                      !$v.VATInvoiceForm.nip.length ||
                        !$v.VATInvoiceForm.nip.required
                    "
                    class="input__error"
                  >
                    Podaj NIP zawierający 10 cyfr np. 1234567890.
                  </p>
                </div>

                <div
                  class="form__box"
                  :class="{
                    'form__box--error':
                      $v.VATInvoiceForm.companyName.$dirty &&
                      $v.VATInvoiceForm.companyName.$invalid
                  }"
                >
                  <p class="input__title">Nazwa firmy</p>
                  <div
                    class="form__inputBox"
                    :class="{
                      'form__inputBox form__inputBox--valid': !$v.VATInvoiceForm
                        .companyName.$invalid
                    }"
                  >
                    <input
                      v-model="VATInvoiceForm.companyName"
                      @blur="$v.VATInvoiceForm.companyName.$touch"
                      type="text"
                      class="form__input"
                      placeholder="podaj nazwę firmy"
                    />
                  </div>
                  <p
                    v-if="!$v.VATInvoiceForm.companyName.required"
                    class="input__error"
                  >
                    Podaj nazwę firmy.
                  </p>
                </div>
              </div>

              <div v-if="isPhysicalDeliverySelected" class="invoice__fBox">
                <label class="form__box__checkbox" for="diffrentAddress">
                  Inny adres faktury niż adres dostawy
                  <input
                    v-model="isDifferentDeliveryPayerAddress"
                    type="checkbox"
                    id="diffrentAddress"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>

              <div v-if="isExtendedPayerForm">
                <div
                  class="form__box"
                  :class="{
                    'form__box--error':
                      $v.VATInvoiceForm.firstName.$dirty &&
                      $v.VATInvoiceForm.firstName.$invalid
                  }"
                >
                  <p class="input__title">Imię</p>
                  <div
                    class="form__inputBox"
                    :class="{
                      'form__inputBox--valid': !$v.VATInvoiceForm.firstName
                        .$invalid
                    }"
                  >
                    <input
                      v-model="VATInvoiceForm.firstName"
                      @blur="$v.VATInvoiceForm.firstName.$touch"
                      type="text"
                      class="form__input"
                      placeholder="podaj imię"
                    />
                  </div>
                  <p
                    v-if="!$v.VATInvoiceForm.firstName.required"
                    class="input__error"
                  >
                    Podaj imię.
                  </p>
                </div>

                <div
                  class="form__box"
                  :class="{
                    'form__box--error':
                      $v.VATInvoiceForm.lastName.$dirty &&
                      $v.VATInvoiceForm.lastName.$invalid
                  }"
                >
                  <p class="input__title">Nazwisko</p>
                  <div
                    class="form__inputBox"
                    :class="{
                      'form__inputBox--valid': !$v.VATInvoiceForm.lastName
                        .$invalid
                    }"
                  >
                    <input
                      v-model="VATInvoiceForm.lastName"
                      @blur="$v.VATInvoiceForm.lastName.$touch"
                      type="text"
                      class="form__input"
                      placeholder="podaj nazwisko"
                    />
                  </div>
                  <p
                    v-if="!$v.VATInvoiceForm.lastName.required"
                    class="input__error"
                  >
                    Podaj nazwisko.
                  </p>
                </div>

                <div
                  class="form__box"
                  :class="{
                    'form__box--error':
                      $v.VATInvoiceForm.street.$dirty &&
                      $v.VATInvoiceForm.street.$invalid
                  }"
                >
                  <p class="input__title">Ulica i numer domu/mieszkania</p>
                  <div
                    class="form__inputBox"
                    :class="{
                      'form__inputBox--valid': !$v.VATInvoiceForm.street
                        .$invalid
                    }"
                  >
                    <input
                      v-model="VATInvoiceForm.street"
                      @blur="$v.VATInvoiceForm.street.$touch"
                      type="text"
                      class="form__input"
                      placeholder="podaj nazwę ulicy i numer domu/mieszkania"
                    />
                  </div>
                  <p
                    v-if="!$v.VATInvoiceForm.street.required"
                    class="input__error"
                  >
                    Podaj nazwę ulicy i numer domu/mieszkania.
                  </p>
                </div>

                <div
                  class="form__box"
                  :class="{
                    'form__box--error':
                      $v.VATInvoiceForm.postalCode.$dirty &&
                      $v.VATInvoiceForm.postalCode.$invalid
                  }"
                >
                  <p class="input__title">Kod pocztowy</p>
                  <div
                    class="form__inputBox"
                    :class="{
                      'form__inputBox--valid': !$v.VATInvoiceForm.postalCode
                        .$invalid
                    }"
                  >
                    <the-mask
                      v-model="VATInvoiceForm.postalCode"
                      @blur.native="$v.VATInvoiceForm.postalCode.$touch"
                      type="tel"
                      class="form__input"
                      placeholder="podaj kod pocztowy"
                      mask="##-###"
                    />
                  </div>
                  <p
                    v-if="!$v.VATInvoiceForm.postalCode.required"
                    class="input__error"
                  >
                    Podaj kod pocztowy.
                  </p>
                  <p
                    v-if="
                      !$v.VATInvoiceForm.postalCode.length ||
                        !$v.VATInvoiceForm.postalCode.numeric
                    "
                    class="input__error"
                  >
                    Podaj kod pocztowy w formacie 00-000.
                  </p>
                </div>

                <div
                  class="form__box"
                  :class="{
                    'form__box--error':
                      $v.VATInvoiceForm.city.$dirty &&
                      $v.VATInvoiceForm.city.$invalid
                  }"
                >
                  <p class="input__title">Miasto</p>
                  <div
                    class="form__inputBox"
                    :class="{
                      'form__inputBox--valid': !$v.VATInvoiceForm.city.$invalid
                    }"
                  >
                    <input
                      v-model="VATInvoiceForm.city"
                      @blur="$v.VATInvoiceForm.city.$touch"
                      type="text"
                      class="form__input"
                      placeholder="podaj nazwę miasta"
                    />
                  </div>
                  <p
                    v-if="!$v.VATInvoiceForm.city.required"
                    class="input__error"
                  >
                    Podaj nazwę miasta.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="isUserNoRegister">
          <div class="container__order">
            <div class="form__box form__box--legal">
              <p class="form__box__title">Zgody prawne i marketingowe</p>
              <RegulationsTerms
                :displayUsageRule="isBasketContainsElectronics"
                ref="regulationsTerms"
              />
            </div>
          </div>
        </template>

        <template v-else>
          <div class="container__order" v-if="isBasketContainsElectronics">
            <div class="form__box form__box--legal">
              <p class="form__box__title">Zgody prawne</p>

              <label
                for="usage_rule"
                class="form__box__checkbox"
                @click="$v.usageRule.$touch()"
                :class="{
                  coloredRed: $v.usageRule.$dirty && $v.usageRule.$invalid
                }"
              >
                <input type="checkbox" id="usage_rule" v-model="usageRule" />
                Zgadzam się na eksploatację treści cyfrowych przed upływem
                14-dniowego terminu do odstąpienia od umowy. Oznacza to
                możliwość korzystania z usług od razu po weryfikacji płatności i
                utratę prawa do odstąpienia od umowy.
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </template>
      </div>

      <div class="col1-3">
        <Summary :deliveryDisplay="isDeliveryAndPaymentChosen" />
        <div class="buttons">
          <div class="buttons__box buttons__box--payment">
            <button
              class="btn"
              :class="{
                'btn--primary': !isOrderInvalid,
                'btn--inactive': isOrderInvalid
              }"
              @click="submit()"
            >
              Zamów i zapłać
            </button>
          </div>

          <div class="buttons__box buttons__box--goBack">
            <button class="btn btn--secondary" @click="goBack()">Wstecz</button>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="showWaitingModal"
      modal-title="Płatność"
      modal-subtitle="Zaraz zostaniesz przekierowany na stronę płatności."
      modal-wait-option
    />
  </div>
</template>

<script>
import Summary from "../components/basket/Summary";
import Header from "../components/basket/Header";
import Steps from "../components/checkout/Steps";
import CustomerEmail from "../components/checkout/CustomerEmail";
import RegulationsTerms from "../components/checkout/RegulationsTerms";
import ErrorBox from "../components/basket/ErrorBox";
import DeliveryAddressForm from "../components/basket/DeliveryAddressForm";
import FormattedPrice from "../components/utils/FormattedPrice";
import { validationMixin } from "vuelidate";
import {
  required,
  requiredIf,
  minLength,
  numeric
} from "vuelidate/lib/validators";
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";
import { gtmManager } from "../gtm";
import Modal from "../components/basket/Modal";
import CompanyOrderInfo from "../components/basket/CompanyOrderInfo";

export default {
  name: "Order",
  mixins: [validationMixin],
  components: {
    "the-mask": TheMask,
    CustomerEmail,
    Header,
    Summary,
    Steps,
    ErrorBox,
    RegulationsTerms,
    Modal,
    DeliveryAddressForm,
    FormattedPrice,
    CompanyOrderInfo
  },
  data() {
    return {
      usageRule: false,
      chosenDelivery: [],
      chosenPayment: [],
      VATInvoice: false,
      userType: null,
      VATInvoiceForm: {
        nip: "",
        companyName: "",
        firstName: "",
        lastName: "",
        street: "",
        postalCode: "",
        city: "",
        country: "Polska"
      },
      retrivedInvoiceData: {
        nip: null,
        kind: null
      },
      isExistingNIPSet: false,
      payerAndReciverAddressErrors: [],
      physicalDeliveryIds: [
        1, // Przesyłka zwykła
        2, // Przesyłka polecona
        3, // Przesyłka lotnicza
        4, // Przesyłka gratisowa
        6, // Za potwierdzeniem odbioru
        8 // Zaliczenie pocztowe
      ],
      isDifferentDeliveryPayerAddress: true,
      gtmManager: gtmManager,
      customerStatus: "existing",
      showWaitingModal: false,
      showCompanyOrderInfo: false
    };
  },
  validations: {
    VATInvoiceForm: {
      nip: {
        minLength: minLength(10),
        required: requiredIf(function() {
          return this.VATInvoice;
        })
      },
      companyName: {
        required: requiredIf(function() {
          return this.VATInvoice;
        })
      },
      firstName: {
        required: requiredIf(function() {
          return this.isExtendedPayerDataRequired;
        })
      },
      lastName: {
        required: requiredIf(function() {
          return this.isExtendedPayerDataRequired;
        })
      },
      street: {
        required: requiredIf(function() {
          return this.isExtendedPayerDataRequired;
        })
      },
      postalCode: {
        length: minLength(5),
        required: requiredIf(function() {
          return this.isExtendedPayerDataRequired;
        }),
        numeric
      },
      city: {
        required: requiredIf(function() {
          return this.isExtendedPayerDataRequired;
        })
      }
    },
    usageRule: {
      sameAs: function(val) {
        if (!this.isBasketContainsElectronics) {
          return true;
        }
        if (this.customerStatus == "new") {
          return !this.termsForm.$v.$invalid;
        }
        return val === true;
      }
    },
    chosenDelivery: { required },
    chosenPayment: { required }
  },
  watch: {
    ...mapGetters(["getDeliveryOptions", "getPaymentOptions"]),
    chosenDelivery: function(val) {
      this.getDeliveryOptions.forEach(elem => {
        if (elem.id === val[0]) {
          this.gtmManager.chosenDeliveryEvent(this.$store, elem.name);
        }
      });
    },
    chosenPayment: function(val) {
      this.getPaymentOptions.forEach(elem => {
        if (elem.code === val[0]) {
          this.gtmManager.chosenPaymentEvent(this.$store, elem.name);
        }
      });
    }
  },
  computed: {
    ...mapGetters([
      "isBasketContainsElectronics",
      "isDeliveryChosen",
      "isUserSynchronized",
      "isUserNoRegister",
      "getDeliveryOptions",
      "getUserType",
      "getCustomerEmail",
      "getPaymentOptions",
      "getLoggedCustomerInvoiceData",
      "getChosenDeliveryPrice",
      "getChosenDeliveryPriceCurrency",
      "getBasketItems",
      "getCustomerNip",
      "getCustomerName"
    ]),
    isPaymentChosen: function() {
      return !!this.chosenPayment.length;
    },
    isDeliveryAndPaymentChosen: function() {
      return this.isPaymentChosen && this.isDeliveryChosen;
    },
    isPhysicalDeliverySelected: function() {
      return this.physicalDeliveryIds.includes(this.chosenDelivery[0]);
    },
    isECardSubscriptionChosen: function() {
      // Płatność autoodnawialna - AE
      return this.chosenPayment.includes("AE");
    },
    isUserTypeCompanySelected: function() {
      if (this.getUserType == null) {
        return this.userType === "Company";
      } else {
        return this.getUserType === "Company";
      }
    },
    isExtendedPayerForm: function() {
      // return (
      //   this.isPhysicalDeliverySelected && this.isDifferentDeliveryPayerAddress
      // );
      return this.isDifferentDeliveryPayerAddress;
    },
    isExtendedPayerDataRequired: function() {
      return (
        (!this.isPhysicalDeliverySelected && this.VATInvoice) ||
        (this.isPhysicalDeliverySelected &&
          this.VATInvoice &&
          this.isDifferentDeliveryPayerAddress)
      );
    },
    isOrderInvalid: function() {
      return (
        this.$v.$invalid ||
        (this.isPhysicalDeliverySelected &&
          this.$refs.deliveryAddressForm.$v.$invalid)
      );
    },
    deliveryOption: function() {
      // Wersja na jednen sposób dostawy
      return this.getDeliveryOptions.length > 0
        ? this.getDeliveryOptions[0]
        : null;
    },
    deliveryOptions: function() {
      // Wersja z dostępnymi wieloma opcjami dostawy
      let dopts = this.getDeliveryOptions;
      return dopts;
    },
    paymentOptions: function() {
      return this.getPaymentOptions;
    },
    redirectUrl: function() {
      let urlParts = window.location.toLocaleString().split("#");
      urlParts[1] = this.$router.resolve("order-confirm").href;
      return urlParts.join("");
    },
    termsForm: function() {
      return this.$refs.regulationsTerms;
    },
    getCompanyName: function() {
      if (
        this.isUserTypeCompanySelected &&
        this.VATInvoiceForm.companyName == ""
      ) {
        return this.getCustomerName;
      }
      return this.VATInvoiceForm.companyName;
    },
    getNIP: function() {
      if (this.isUserTypeCompanySelected && this.VATInvoiceForm.nip == "") {
        return this.getCustomerNip;
      }
      return this.VATInvoiceForm.nip;
    },
    payerAddressData: function() {
      return {
        ...this.VATInvoiceForm,
        postCode: this.VATInvoiceForm.postalCode,
        kind: this.getUserType,
        email: this.getCustomerEmail,
        invoiceEmail: this.getCustomerEmail,
        nip: this.getNIP,
        companyName: this.getCompanyName,
        dataSourceId: "98cc31ae-6b51-4455-8e8a-20d4f3fcc4b8",
        countryIso2Code: "PL"
      };
    },
    physicalAddressData: function() {
      return {
        ...this.$refs.deliveryAddressForm.$data,
        postCode: this.$refs.deliveryAddressForm.postalCode,
        kind: this.getUserType,
        email: this.getCustomerEmail,
        invoiceEmail: this.getCustomerEmail,
        nip: this.getNIP,
        companyName: this.getCompanyName,
        dataSourceId: "98cc31ae-6b51-4455-8e8a-20d4f3fcc4b8",
        countryIso2Code: "PL"
      };
    }
  },
  methods: {
    ...mapActions([
      "editCustomer",
      "selectDelivery",
      "selectPayment",
      "makeOrderForCustomer",
      "loadBasketData"
    ]),
    goBack() {
      this.$router.push({ name: "Basket" });
    },
    checkForm(form) {
      form.$v.$touch();
      return form.$v.$invalid;
    },
    submit() {
      this.$v.$touch();
      let invalidTermsForm = false;

      if (this.isUserNoRegister) {
        invalidTermsForm = this.checkForm(this.termsForm);
        this.usageRule = !invalidTermsForm;
      }

      if (this.$v.$invalid || invalidTermsForm) {
        return;
      }
      this.orderAndPay();
    },
    getAddressess() {
      let payerAddressData = null; // dane płatnika wymagane w przypadku dostawy lub faktury
      let physicalAddressData = null; // dane odbiorcy do fizycznych produktów

      if (this.physicalAddressData.kind == null) {
        this.physicalAddressData.kind = this.userType;
      }

      if (this.payerAddressData.kind == null) {
        this.payerAddressData.kind = this.userType;
      }

      // Dane adresu do dostawy
      if (this.isPhysicalDeliverySelected) {
        physicalAddressData = this.physicalAddressData;
      }

      // Dane adresu do faktury
      if (this.VATInvoice && this.isDifferentDeliveryPayerAddress) {
        payerAddressData = this.payerAddressData;
      } else {
        payerAddressData = physicalAddressData;
      }

      return { payerAddressData, physicalAddressData };
    },
    async addAddressesToCustomer() {
      this.payerAndReciverAddressErrors = [];
      const { payerAddressData, physicalAddressData } = this.getAddressess();

      if (payerAddressData || physicalAddressData) {
        try {
          await this.editCustomer({
            payerAddress: payerAddressData,
            reciverAddress: physicalAddressData
          });
        } catch (error) {
          this.payerAndReciverAddressErrors.push(error.message);
          this.$refs.errorBox.$el.scrollIntoView({ behavior: "smooth" });
          throw new Error();
        }
      }
    },
    async orderAndPay() {
      let orderData = {
        customerStatus: this.customerStatus,
        url: this.redirectUrl
      };
      if (this.userType == null) {
        this.setUserType();
      }
      if (this.isUserNoRegister) {
        orderData.salesReg = this.termsForm.salesReg;
        orderData.newsletter = this.termsForm.newsletter;
        orderData.invoiceAddress = {
          email: this.getCustomerEmail,
          firstName: null,
          lastName: null,
          nip: null,
          street: null,
          city: null,
          postalCode: null,
          country: null,
          countryIso2Code: "PL"
        };

        const { payerAddressData, physicalAddressData } = this.getAddressess();

        if (payerAddressData) {
          orderData.invoiceAddress = payerAddressData;
        }
        if (physicalAddressData) {
          orderData.deliveryAddress = physicalAddressData;
        }
      } else {
        try {
          await this.addAddressesToCustomer();
        } catch (error) {
          return;
        }
      }

      try {
        this.showWaitingModal = true;
        await this.makeOrderForCustomer(orderData);
      } catch (error) {
        this.showWaitingModal = false;
        console.error(error.message);
      }
    },
    setPaymentOption(option) {
      this.chosenPayment = [option.code];
      this.selectPayment(option.id);
    },
    setDeliveryOption(option) {
      this.chosenDelivery = [option.id];
      this.selectDelivery(option.weaverId);
    },
    setInitialDelivery() {
      for (let i = 0; i < this.getDeliveryOptions.length; i++) {
        const option = this.getDeliveryOptions[i];
        if (option.isSelected) this.setDeliveryOption(option);
      }
    },
    setInitialPayment() {
      for (let i = 0; i < this.getPaymentOptions.length; i++) {
        const option = this.getPaymentOptions[i];
        if (option.isSelected) this.setPaymentOption(option);
      }
    },
    retriveCustomerInvoiceData() {
      const invoiceData = this.getLoggedCustomerInvoiceData;
      if (invoiceData) {
        this.retrivedInvoiceData.nip = invoiceData.nip;
        this.retrivedInvoiceData.kind = invoiceData.kind;
      }
    },

    setUserType() {
      if (this.VATInvoice) {
        this.userType = "Company";
      } else {
        this.userType = "Private";
      }
    },
    isCashOnDelivery(paymentOption) {
      const code = paymentOption;
      const idDeliveryOption = 8;
      if (code === "PO") {
        this.chosenDelivery = [idDeliveryOption];
      }
    },
    isPln(currency) {
      return currency === "PLN";
    },
    canGetInvoice() {
      if (this.getBasketItems !== undefined && this.getBasketItems.length > 0) {
        if (this.getBasketItems[0].price.currencyIsoCode === "USD") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  },
  async mounted() {
    if (this.isUserNoRegister) {
      this.customerStatus = "new";
    }

    try {
      await this.loadBasketData(this.customerStatus);
      this.retriveCustomerInvoiceData();
    } catch (error) {
      console.error(error);
    }

    this.setInitialPayment();
    this.setInitialDelivery();
  }
};
</script>

<style lang="scss" scoped>
.container {
  &__order {
    background-color: $lightBgColor;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 8px;

    @include lg {
      margin-top: 0;
      margin-bottom: 0;
    }

    &--user {
      @include lg {
        padding-bottom: 38px;
      }
    }

    &--vatBox {
      padding: 16px 8px;
    }
  }
}

.buttons {
  .btn {
    margin-bottom: 8px;

    &--secondary {
      color: #4f4f4f;
      border: 1px solid #4f4f4f;
      background-color: inherit;

      @include lg {
        color: #979797;
        border-color: #979797;
      }
    }
  }

  .buttons__box {
    padding: 10px;

    &--payment {
      background-color: #fff;
    }
    &--goBack {
      background-color: transparent;

      @include lg {
        padding-top: 16px;
      }
    }
  }
}

.form__box {
  margin-top: 0;

  &--min-height {
    min-height: 100px;
  }

  &__title {
    @include font-xl-n;
    color: #181818;
    margin-bottom: 16px;
  }

  &__info {
    margin-top: 15px;
  }

  &__txt {
    color: #505050;
    font-size: 12px;
    line-height: 14px;
    margin-left: 25px;

    &Box {
      display: flex;
      align-items: center;
    }

    &--margin {
      margin-top: 20px;
      margin-left: 5px;
    }
  }

  &__user {
    display: flex;
    justify-content: space-between;
  }

  &--legal {
    label {
      line-height: 14px;
      padding-left: 41px;
    }
  }

  &__deliveryCost {
    font-size: 12px;
    line-height: 14px;
    margin-top: 17px;

    &__price {
      font-weight: 500;
    }
  }

  // checkbox
  &__checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 12px;
    line-height: 25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #505050;

    @include lg {
      font-size: 14px;
    }

    input {
      display: none;
      position: absolute;
      left: -100vw;
    }

    input:checked ~ .checkmark {
      background-color: var(--mainColor);
      border: 1px solid transparent;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }
  }
}

.usageRule {
  line-height: 14px !important;
}

.user {
  &__mail {
    color: #3ec6ff;
    @include font-xl-n;

    &Change {
      color: #505050;
      font-size: 11px;
      line-height: 14px;
      text-decoration: underline;
    }
  }
}

.order {
  &Input {
    position: absolute;
    left: -100vw;
    display: none;

    &Label {
      font-size: 12px;
      line-height: 14px;
      color: #484646;
      border: 1px solid #727272;
      box-sizing: border-box;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.11);
      border-radius: 5px;

      width: 86px;
      min-width: 86px;
      max-width: 86px;
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      word-break: break-word;
      padding: 4px;
      text-align: center;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  &Input__checked + .orderInputLabel {
    border: 1px solid var(--optionColor);
  }

  .summary__product {
    margin-bottom: 6px !important;
  }
}

.input {
  &Box {
    display: flex;

    @include lg {
      flex-wrap: wrap;

      .form__box__txt {
        margin-left: 0;
      }

      .form__box__txtBox {
        margin-top: 8px;
      }
    }
  }
}

// delivery

.delivery {
  &Options {
    display: flex;
    justify-content: flex-start;

    &__option {
      display: flex;
      flex-direction: column;
      margin-right: 18px;
    }

    .orderInputLabel {
      text-align: center;
    }
  }
}

// payment

.paymentOptions {
  display: flex;
  justify-content: flex-start;

  &__option {
    display: flex;
    flex-direction: column;
    margin-right: 18px;
  }

  .orderInputLabel {
    text-align: center;
  }
}

.paymentInput__title {
  margin-top: 6px;
}

// invoice

.invoice {
  &__checkbox {
    display: flex;
    margin-bottom: 16px;

    .orderInputLabel {
      margin-right: 18px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  &__title {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 16px;

    &--fvat {
      margin-top: 16px;
    }
  }

  &__fBox {
    margin-top: 16px;
  }

  .form__box {
    margin-top: 16px;
  }
}

// checkmark
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #868686;
  border-radius: 4px;
}

.checkmark__disabled {
  background: #868686;
  cursor: default;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
label.orderInputLabel.orderInputLabel--payment {
  height: 100px;
  /* width: 120px; */
  min-width: 100px;
}
.checkmark:after {
  left: 7px;
  top: 2px;
  width: 9px;
  height: 16px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.coloredRed {
  color: #eb5757;

  .checkmark {
    border: 1px solid #eb5757;
  }
}
</style>
